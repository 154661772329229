import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import withThankyouWrapper from '../../components/ThankyouPages/withThankyouWrapper';
import { getData } from '../../utils/utilityFunctions';

const fadeIn = keyframes`
  0% {
    transform: scale(0);
    opacity: 0;
  },
  100% {
    transform: scale('100');
    opacity: 1;
  }
`;

const IntroBlock = styled.div`
  font-family: 'Poppins', sans-serif;
  box-sizing: border-box;
  background: #033652;
  color: #fff;
  text-transform: uppercase;
  padding: 10px;
  margin: 0 auto;
  text-align: center;

  h1 {
    font-size: clamp(20px, 3vw, 28px);
    font-weight: 300;
    margin: 0;
  }
  h3 {
    font-size: 18px;
    font-weight: 400;
  }
  .ybm {
    font-size: clamp(30px, 4.5vw, 65px);
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    margin: 0;
    padding: 0;
  }
`;
const TYContainer = styled.div`
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
  --tyBlue: #00b2a4;
  --tyGreen: #00b2a4;

  font-family: 'Poppins', Arial, san-serif;
  width: 100%;
  margin: auto;
  img {
    margin: 0;
  }
  ul {
    display: block;
    margin: 0;
    padding: 0;
    column-count: 1;
  }
  section {
    box-sizing: border-box;
    width: calc(100vw - 40px);
    max-width: 700px;
    padding: 10px 20px 10px;
    margin: auto;
    border-radius: 10px;
    box-shadow: 0 3px 8px #555;
    margin-bottom: 10px;
    @media (max-width: 801px) {
      padding: 10px;
    }
  }
  .featuredText {
    text-align: center;
    font-size: clamp(20px, 3vw, 28px);
    font-weight: 700;
    text-transform: uppercase;
    margin: 20px 0;
  }
  .additionalHeadline {
    text-align: left;
    font-size: clamp(15px, 3vw, 26px);
    font-weight: 500;
    margin: 20px auto;
    width: calc(100% - 30px);
    max-width: 1160px;
  }
  .contactInfo {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 980px;
    margin: auto;
    .phoneNumber {
      background: #07698e;
      color: #fff;
      padding: 15px;
      border-radius: 5px;
      margin-top: 10px;
      a {
        color: #fff;
      }
      @media (max-width: 801px) {
        width: 100%;
        text-align: center;
      }
    }
    .clickApply {
      height: 49px;
      background: #07698e;
      color: #fff;
      padding: 15px;
      border-radius: 5px;
      margin-top: 10px;
      cursor: pointer;
      @media (max-width: 801px) {
        width: 100%;
        text-align: center;
      }
    }
    @media (max-width: 801px) {
      flex-wrap: wrap;
    }
  }
  .logoContainer {
    width: calc(100% - 20px);
    max-width: 415px;
    margin: 0 auto 20px;
    animation: ${fadeIn} 0.9s;
    img {
      width: 100%;
    }
  }

  .questions {
    font-family: 'Poppins', sans-serif;
    box-sizing: border-box;
    background: #e0e1e3;
    border: none;
    border-radius: 0;
    box-shadow: none;
    width: 100%;
    max-width: 100%;
    color: #333;
    padding-bottom: 20px;
    .questionsContainer {
      max-width: 1170px;
      margin: auto;
    }
    .questionsHeadline {
      text-align: center;
      font-size: 28px;
      font-weight: 700;
      color: #00a6ce;
    }
    .threeItemBlock {
      margin-top: 20px;
      display: flex;
      width: 100%;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 35px;
      .contentBlock {
        width: 31%;
        min-height: 100px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        text-align: left;
        margin-bottom: 20px;
        p {
          font-size: 16px;
          font-weight: 600;
          line-height: 1.1;
          width: 100%;
          margin-left: 15px;
          margin-bottom: 0;
        }
        .iconContainer {
          width: 120px;
          height: 85px;
          background: #fff;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 65%;
          }
        }
        @media (max-width: 801px) {
          width: 100%;
        }
      }
      @media (max-width: 801px) {
        flex-wrap: wrap;
      }
    }
    .ctcLink {
      text-decoration: none;
    }
    .dividerContainer {
      box-sizing: border-box;
      width: calc(100% - 20px);
      max-width: 700px;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--tyGreen);
      color: #fff;
      padding: 10px 8px;
      border-radius: 50px;
      margin: -20px auto 30px;
      box-shadow: 0 5px 12px #555;
      min-height: 95px;
      animation: ${fadeIn} 0.9s;
      h2 {
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        margin: 0;
        font-size: clamp(24px, 3.75vw, 34px);
        font-weight: 400;
        text-align: center;
        .phone {
          font-size: 0.8em;
          font-weight: 400;
        }
      }
    }
    .questionList {
      max-width: 1160px;
      margin: 20px auto 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      ul {
        display: block;
        column-count: 1;
        column-gap: 20px;
        font-size: 16px;
        font-weight: 400;
        padding-left: 5px;
        width: 40%;
        @media (max-width: 801px) {
          column-count: 1;
          width: 100%;
        }
        li {
          list-style-type: none;
          margin-bottom: 10px;
          padding: 0;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          font-size: 16px;
          line-height: 1.25;
          .bullet {
            width: 12px;
            margin-right: 5px;
            svg {
              width: 100%;
            }
          }
          p {
            max-width: calc(100% - 20px);
          }
          a {
            text-decoration: none;
            color: #333;
          }
        }
      }
      @media (max-width: 801px) {
        flex-wrap: wrap;
        justify-content: center;
      }
    }
  }
  .buttonContainer {
    display: flex;
    align-items: center;
    justify-content: space-around;
    max-width: 960px;
    margin: 0 auto;
    @media (max-width: 801px) {
      flex-direction: column;
    }
    a {
      width: 100%;
      @media (max-width: 801px) {
        width: 100%;
      }
    }
  }
  .ehoContainer {
    max-width: 60px;
    padding-right: 10px;
  }

  .equalHousingContainer {
    display: flex;
    width: 65%;

    p {
      font-size: 13px;
    }
    @media (max-width: 801px) {
      flex-wrap: wrap;
      width: 100%;
    }
  }

  .equalHousingLogoContainer {
    width: calc(100% - 20px);
    max-width: 60px;
    margin: 0 8px;
    img {
      width: 100%;
    }

    @media (max-width: 801px) {
      margin: 8px auto 0;
    }
  }

  .circle {
    fill: var(--tyBlue);
  }
`;

const EsmThankyou = () => {
  const [redirectLink, setRedirectLink] = useState('');
  const credit = getData('credit_rating');

  useEffect(() => {
    const goodCreditArray = ['excellent', 'good'];
    if (goodCreditArray.includes(credit)) {
      setRedirectLink(
        'https://trkthat4.com/?oex3=Utiwp2GtajMVc2XwFz6j%2bzHyoULgxPWH&s1='
      );
    } else {
      setRedirectLink(
        'https://trkpls4.com/?lnwk=j%2fyP9wDullZCUb%2fO9P03HecV2pp1jy6g&s1='
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <TYContainer>
        <IntroBlock>
          <h1>
            <span className="ybm">Mortgage Lending Done Right!</span>
          </h1>
        </IntroBlock>
        <p className="featuredText">
          You've been matched with our featured provider:
        </p>
        <div className="logoContainer">
          <img
            src="https://s3-us-west-1.amazonaws.com/offers.printfingertech.net/images/cardinalLogo.png"
            alt="Cardinal Financial Company"
          />
        </div>

        <section className="questions">
          <div className="questionsContainer">
            <div className="threeItemBlock">
              <div className="contentBlock">
                <div className="iconContainer">
                  <img
                    src="https://s3-us-west-1.amazonaws.com/offers.printfingertech.net/images/headPhone.png"
                    alt="Head phones"
                  />
                </div>
                <p>
                  Getting a mortgage doesn't have to give you a migraine.
                  Cardinal Financial's experienced loan team is standing by to
                  review what you can expect from the purchase process.
                </p>
              </div>
              <div className="contentBlock">
                <div className="iconContainer">
                  <img
                    src="https://s3-us-west-1.amazonaws.com/offers.printfingertech.net/images/sunCheckMark.png"
                    alt="Sun"
                  />
                </div>
                <p>
                  Cardinal Financial offers a secure, fast, and detailed home
                  financing process designed for your unique financial needs and
                  aims to get you pre-approved in no time.
                </p>
              </div>
              <div className="contentBlock">
                <div className="iconContainer">
                  <img
                    src="https://s3-us-west-1.amazonaws.com/offers.printfingertech.net/images/dollarHouse.png"
                    alt="house"
                  />
                </div>
                <p>
                  Our loan originators will determine the right loan for you.
                  Your finances. Your homeownership goals. Believe it or not,
                  there's a mortgage that can satisfy all of them.
                </p>
              </div>
            </div>
            <div className="buttonContainer">
              <a
                href={redirectLink}
                className="ctcLink"
                target="_blank"
                rel="noreferrer"
              >
                {redirectLink && (
                  <div className="dividerContainer">
                    <h2>
                      Apply Now
                      {/* <span className="phone">(855) 912-2434</span> */}
                    </h2>
                  </div>
                )}
              </a>
            </div>
            <div className="questionList">
              <ul>
                <li>
                  <div className="bullet">
                    <svg
                      viewBox="0 0 100 100"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="50" cy="50" r="50" className="circle" />
                    </svg>
                  </div>
                  <p>How much do I need for a downpayment?</p>
                </li>
                <li>
                  {' '}
                  <div className="bullet">
                    <svg
                      viewBox="0 0 100 100"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="50" cy="50" r="50" className="circle" />
                    </svg>
                  </div>
                  <p>Will I close within 30 days?</p>
                </li>
                <li>
                  {' '}
                  <div className="bullet">
                    <svg
                      viewBox="0 0 100 100"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="50" cy="50" r="50" className="circle" />
                    </svg>
                  </div>
                  <p>Will I receive updates from my lender?</p>
                </li>
              </ul>
              <div className="equalHousingContainer">
                <div className="equalHousingLogoContainer">
                  <img
                    src="https://s3-us-west-1.amazonaws.com/offers.printfingertech.net/images/Equal_Housing_Opportunity_Black.png"
                    alt="Equal Housing Opportunity"
                  />
                </div>
                <p>
                  Visit the NMLS Consumer Access website for regulatory
                  information about us. Cardinal Financial Company, Limited
                  Partnership (NMLS ID 66247).Copyright © 2022 Cardinal
                  Financial Company, Limited Partnership. All trademarks and
                  tradenames are the property of Cardinal Financial.
                </p>
              </div>
            </div>
          </div>
        </section>
      </TYContainer>
    </>
  );
};

export default withThankyouWrapper(EsmThankyou);
